import React from "react"
import Layout from "src/components/common/Layout"
import HeroSection from "src/components/page/home/HeroSection"
import ChannelsSection from "src/components/common/ChannelsSection"
import ScheduleCarouselSection from "src/components/page/home/ScheduleCarouselSection"
import ShowsSection from "src/components/page/home/ShowsSection"
import YoutubeSection from "src/components/page/home/YoutubeSection"
import ProgrammingSection from "src/components/page/home/ProgrammingSection"

const HomePage = ({
  title,
  subtitle,
  channelTitle,
  videoUrl,
  videoAlt,
  seriesName,
}) => {
  return (
    <Layout>
      <HeroSection
        title={title}
        subtitle={subtitle}
        videoUrl={videoUrl}
        videoAlt={videoAlt}
      />
      <ChannelsSection seriesName={seriesName} title={channelTitle} />
    </Layout>
  )
}

export default HomePage
